import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VideoBackground from 'vue-responsive-video-background-player'

import router from './router';

import './assets/styles/main.scss';

Vue.use(VueRouter);

Vue.component('video-background', VideoBackground);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
