import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const loadView = (path) => {
  return () => import(
    /* webpackChunkName: "view-[request]" */
    `@/views/${path}.vue`
  )
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: loadView("Home"),
  },
  {
    path: "/services",
    name: "Services",
    component: loadView("Services"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: loadView("Contacts"),
  },
  {
    path: "/track/:id",
    name: "TrackInfo",
    component: loadView("TrackInfo"),
  },

  {
    path: "/auth/login",
    name: "AuthLogin",
    component: loadView("Auth/Login"),
  },

  {
    path: "/admin-panel",
    name: "AdminPanel",
    component: loadView("Admin/Home"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/orders",
    name: "AdminPanelOrders",
    component: loadView("Admin/Orders"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/messages",
    name: "AdminPanelMessages",
    component: loadView("Admin/Messages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/trackers",
    name: "AdminPanelTrackers",
    component: loadView("Admin/Trackers"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/tracker/create",
    name: "AdminPanelTrackerCreate",
    component: loadView("Admin/TrackerCreate"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/tracker/view/:id",
    name: "AdminPanelTrackerView",
    component: loadView("Admin/TrackerView"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-panel/tracker/edit/:id",
    name: "AdminPanelTrackerEdit",
    component: loadView("Admin/TrackerEdit"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/403",
    name: "AccessDenied",
    component: loadView("AccessDenied")
  },
  {
    path: "*",
    name: "NotFound",
    component: loadView("NotFound")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuth = localStorage.getItem("isAuth") === "true";

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresNotAuth = to.matched.some(record => record.meta.requiresNotAuth);

  if (requiresAuth && !isAuth) {
    next({
      name: "AuthLogin"
    });
    return;
  }

  if (requiresNotAuth && isAuth) {
    next({
      name: "Home"
    })
    return;
  }

  next()
})

export default router;
